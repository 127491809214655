import { TFormFieldsFactory } from '../models/form-field.model';

export const newsletterConsentForm: TFormFieldsFactory<
    'NewsletterConsentForm',
    { consentContent: string | null }
> = ({ t, consentContent }) => [
    {
        name: 'consent',
        type: 'checkbox',
        label: consentContent ?? '',
        initialValue: '',
    },
    {
        name: 'submit',
        type: 'submit',
        label: t.submit,
    },
];
